
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.custom-card {
  transition: all 0.2s ease-in-out;
  box-shadow: 6px 0px 8px 0px rgba(73, 73, 73, 0.252);
}


.custom-card:hover {
  transform: scale(1.02); 
  box-shadow: 6px 0px 8px 0px rgba(73, 73, 73, 0.355);
  cursor: pointer;

}

.glossy {
  background-color: rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(5px);

}

.custom-header {
 background-color: rgba(101, 131, 220, 0.102) !important;
}

.App-header {
  background-color: #e0e4ec;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.background {
  background-image: url("https://512pixels.net/wp-content/uploads/2020/06/11-0-Color-Day-thumbnails.jpg");
 
  height: 100vh;
  width: 100%;

  background-size: cover;
  background-position: center;
  overflow: auto

  
}

.trans-bg {
  background-color: blue !important;
}

.card {
  border: none !important;
}

.contactCard {
  background-color: rgba(255, 255, 255, 0.64) !important;
  backdrop-filter: blur(5px);
}

.contactCard:hover {
  transform: none !important;
  cursor: default !important;

}

.connectCard {
  background-color: rgba(255, 255, 255, 0.47) !important;
  border: none !important;
  box-shadow: none !important;
  cursor:  default !important;
  backdrop-filter: blur(5px);
}

.connectCard:hover {
  transform: none !important;
}

.connectDiv {
  transition: all 0.2s ease-in-out;
}

.connectDiv:hover {
  transform: scale(1.02); 
  cursor: pointer;
}

.iconLink {
  text-decoration: none;
  color: white;
}
.iconLink:hover{
  text-decoration: none;
  color: white;
}

.page-section-nav {
  background-color: white;
  padding: 10px;
  margin-bottom: 15px;
  transition: 0.3s;
}

.aboutCard {
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

.vertical-nav > * {
  padding: 15px;
  width: 100%;
  border-top: 0.6px solid #9fa6b23a;
}

.vertical-nav > *:first-child {
  border-top: none;
}


.vertical-nav {
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.first-header {
 color: #41AFDC;
}

.second-header {
  color: #FF3126;
 }

 .third-header {
  color: #1EC337
 }

 .fourth-header {
  color: #5452CC;
 }

 .fifth-header {
  color: #FF9500;
 }


 .download-page {
  background-color: #41b0dc22;
  border: 0.3px solid #41b0dc88;
 }

 .download-page-other {
  background-color: #41b0dc0c;
  border: 0.3px solid #41b0dc88;

 }
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.vertical-navigation {
    height: 100vh !important;
}


@media (min-width: 200px) and (max-width: 991.98px) { 
  .vertical-navigation {
    height: auto !important;

}
}
